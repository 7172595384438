import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { BlogEntry } from "../../../components";
import { DesktopRightImage, PhoneImage } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Probably no other nation has such a dedicated cycling community. From road to
downhill biking, the french riders compete in the highest levels. The
possibilities for mountain biking in France are on an equally high level!
From the highest peaks of the Alps to the Provence at the Mediterranean Sea,
the Pyrenees at the Spanish boarder, or lower range mountains like the Massiv
Central or the Vosges mountains. Find great trails of all kinds, and a crazy
bike scene in France.`}</p>
    <br />
    <Link className="text-black" to="/destination/france/sospel" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/sospel_8.jpg" mdxType="DesktopRightImage" />
        <h2>{`Sospel`}</h2>
        <h4>{`Shuttle paradise in the back country of the Côte d'Azur`}</h4>
        <p>{`The sleepy city of Sospel, with a French/Italian charm, offers great mountain
biking. A trail network developed by Ash Smith, the man behind the Trans
Provence, is easily accessible by shuttle."`}</p>
        <PhoneImage src="destinations/sospel_8.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      